
const lang = {
  PageNotFound: "Don't know where you are?",
  sorry: "We really have no idea either ...",
  btnReturn: "Back to Homepage",
  PageNotAuthorized: "Don't know where you are?",
  /*-----------------Tenant Number-----------------------*/
  hello_tenant: "Log in",
  enter_tenant_phone: "Please enter your phone number",
  Continue: "Continue",
  enter_phone: "Enter phone number",
  youHaveToBeInvited: "Your Property Manager has to invite you. You can try again or go to Homepage",
  /*-------Tenant history --------*/
  tenant_history_date: "Due date",
  tenant_history_category: "Category",
  tenant_history_paymentDate: "Payment date",
  tenant_history_propUnit: "Unit",
  tenant_history_Amount: "Amount (KWD)",
  tenant_history_method: "Payment method",
  tenant_history_action: "Remarks",
  status: "Status",
  /*----Tenant Receip ----*/
  ARV_Outstanding: "Outstanding",
  UR_MSGEnd: "Sure you want to end Contract?",
  ARV_Paid: "Paid",
  ARV_Overdue: "Overdue",
  ARV_Search: "Search",
  ARV_Status: "Status",
  ARV_DueDate: "Due date",
  ARvName: "Property name",
  ARvUnitFloor: "Unit & floor",
  ARvNanlord: "Landlord name",
  ARvTenantName: "Tenant name",
  RTvTransactionNbr: "Transaction number",
  ARV_DatePayment: "Payment Date & method",
  ARVReceiv: " View Receipt",
  RTVRecord: "Record Payment",
  ARVRe: "Rental receipt",
  ARVRReceipt: "Receipt",
  ARVRt: "From",
  ARTRTo: "to",
  ARTAmout: "Amount paid",
  ARTPyment: "Payment method",
  AETRef: "Reference number",
  AETPaymentdate: "Payment date",
  AETPaymentmeth: "Payment method",
  AETPaymentCash: "Cash",
  AETPaymentCheque: "Cheque",
  AETPaymentCreditcrd: "Credit card",
  AETPaymentBque: "Bank Transfer",
  selectAe: "Select",
  selectAeUnit: "Select unit",
  selectAeProperty: "Select property",
  refnmbrRecod: "Enter Reference number",
  AETSubmit: "Submit",
  RTVStsend: "Unpaid",
  RTVStpaid: "Paid",
  RTVStoverdue: "Overdue",
  deleteUnitt: "Delete Unit",
  ARVRecord: "Record payment",
  UnitRented_KWD: "KWD",
  UnitRented_export: "Export",
  InvoiceReceipt: "Invoice Receipt",
  Invoice: "Invoice",
  Payment_for: "Payment for",
  Rent_for: "Rent for",
  DepositFor: "Security deposit for",

  /************************Tenant Payment****************** */
  Payment: "Payment",
  History: "History",
  Total: "Total",
  payAll: "Pay all",
  pay: "Pay",
  payinAdvance: "Pay In Advance",
  Due_date: "Due date",
  Payment_Of: "Payment Of",
  Category: "Category",
  Property_Unit: "Unit",
  Pay_to: "Pay to",
  Subject: "Subject",

  /********************In advance payment******************************************* */
  PropertySelect: "Property",
  UnitSelect: "Unit",
  PaydAdv_Months: "Months",
  PaydAdv_Pay_for: "Pay for",
  transferAmount: "Amount",
  next: "Continue",
  AETCancel: "Cancel",
  /*-----------------NavBAr-----------*/
  Statistics: "Statistic",
  NavbarPay: "Payment",
  Rentals: "Rental",
  Maintenance: "Maintenance",
  /***********************psw Tenant *****************/
  pswTenantgreat: "Create password",
  pswTenantcreatepsw: "Choose a password for your account registered to +965xxxx",
  pswTenantPassword: "Password",
  pswTenantConfirmPassword: "Confirm password",
  cnxTeantwelcomeBack: "Welcome back!",
  cnxTenantEnterPassword: "Enter your password",
  cnxTenantEnterPasswordPlaceholder: "Your password",
  cnxTenantContinue: "Log In",
  phoneVerificationButtonBackTenant: "Back to log in",
  smsverificationTenantContinue: "Continue",
  newPasswordTenant: "New password",
  forgotPasswordMessageWarning1: "Wrong password",
  /*----F Pswd -----*/
  ResetPassword: "Reset password",
  messagePhoneVerification: "Please enter your phone number to reset password.",
  phoneVericationPlaceHolder: "Add phone number",
  phoneVerificationButtonBack: "Back to log in",
  forgotPasswordThankyou: "Thank you !",
  forgotPasswordMessage:
    "We’ve sent you an sms with code, please enter the code to verify your number.",
  forgotPasswordWarning: "This code is invalid",
  forgotPasswordResend: "Resend code again",
  forgotPasswordResetPassword: "ResetPassword",
  forgotPasswordNewPassword: "New Password",
  forgotPasswordConfirmPassword: "Confirm password",
  forgotPasswordButtonConfirmation: "Submit",
  forgotPasswordMessageWarning: "Please make sure your number is valid",
  forgotPasswordPasswordGuidance: "Password guidance",
  forgotPasswordNumbers: "Numbers",
  forgotPasswordLowerCaracter: "Lower case",
  forgotPasswordUperCase: "Upper case",
  forgotPasswordMinCaracter: "Min 8 characters",
  forgotPasswordConfirm: "Confirm",
  /******************************Settings******** */
  profile: "Profile",
  upload_photo: "Upload Photo",
  full_name: "Full name",
  email_address: "Email address",
  logout: "Log out",
  password: "Change password",
  change_your_password: "You can change your password at anytime",
  contact_information: "Info",
  phone_number: "Phone number",
  change_password: "Change password",
  edit: "Edit",
  add_your_phone_number: "Add your phone number",
  Civil_ID: "Civil ID (2 sides)",
  id_number: "ID number",
  update_password: "Change Password",
  Numbers: "Numbers",
  Lower_caracter: "Lower Caracter",
  Uper_case: "Uper case",
  Min_caracter: "Min 8 Caracter",
  forgotPasswordSpeicalChar: "Special Character",
  Password_guidance: "Password guidance",
  current_password: "Current password",
  new_password: "New password",
  cnxTenantForgotPassword: "Forget Password ?",
  language: "Language",
  selectLanguage: "Select your preferred language",
  english: "English",
  arabic: "Arabic",
  this_password_is_incorrect: "You have entered a wrong password",
  subject: "Subject",
  /**************************Modal Payment *******************/
  AddCard: "Add card",
  Knet: "KONNECT",
  PayNow: "Pay Now",
  CVV: "CVV",
  ExpirationDate: "Expiration date",
  CardNumber: "Card Number",
  FullNameModal: "Full Name",
  Amount: "Total amount",
  PayTo: "Pay to",
  PaymentDate: "Payment date",
  NumberMonths: "Number of months",
  Checkout: "Checkout",
  PayWith: "Pay with",
  enterFullName: "enter full name",
  property: "property",
  Property: "Property",
  cancel: "Cancel",
  save: "Save ",
  Documents: "Civil ID (2 sides)",
  verifyInformation: "You must verify your information",
  errorMessageAmount: "Amount value should be more than 1 KWD",
  /**************Rentals Status*********** */
  UnitRented_Beds: "Bedrooms",
  UnitRented_Bathrooms: "Bathrooms",
  Rent: "Rent",
  SignUpPassword: "Create password",
  Browse: " Upload",
  /**************Rentals Status*********** */
  UnitRented_SQft: "Sq.ft",
  Rents: "Rent",
  confirm: "Log In",
  active: "Active",
  expired: "Expired",
  pending: "Pending",
  contract: "Contract",

  /****************StayTuned *************/
  stay_tuned: "Stay tuned",
  comming_soon: "This feature is coming soon !",
  ComingSoon: "Coming soon",
  Accept_Terms: "Please accept the terms and conditions",
  merchant_id: "Merchant track ID",
  transaction_id: "Transaction ID",
  payment_id: "PaymentID",

  /***********************Statistic ***************/
  FilterByUnit: "Filter by unit",
  outstanding: "Outstanding",
  kwd: "KWD",
  maintenace: "Maintenance",
  unresolved: "Unresolved",
  resolved: "Resolved",
  nextPaymentIn: "Next payment in",
  Days: "Days",
  contractEndIn: "Contracts ends in",
  seeAll: "See All",
  Month: "Month",
  Months: "Months",
  /*********View contract************** */
  Contract: "Contract",
  Date: "Date",
  to: "to",
  rent: "Rent amount",
  kw: "KWD",
  Deposit: "Security deposit",
  accept_contract: "Accept Contract",
  access: "Access dashboard",
  great: "Great !, your rent is ready",
  you_access: "You can access your dashboard now.",
  viewContract: "View Contract",
  delete: "Delete",
  reject_contract: "Reject",
  accessDashbord: "Access dashboard",
  FullName: "FullName",
  labelRegister: "Name",
  enterEmail: "Enter email",
  labelRegisterEmail: "Email adress",
  birth_date: "Add date",
  forgotPasswordConfirmDone: "Done",
  /****************maintenance**********************/
  openTicket: "Open Ticket",
  Unresolved: "Unresolved",
  Resolved: "Resolved",
  Unit: "Unit",
  Title: "Title",
  Details: "Details",
  Select: "Select",
  AddSubject: "Add subject",
  Message: "Message",
  maintenanceDeleteTxt: "Sure you want to delete ticket ?",
  maintenanceDeleteBtn: "Delete",
  maintenanceCancelBtn: "Cancel",
  Submit: "Submit",
  Cancel: "Cancel",
  required_unit_name: "Unit name is required",
  required_title: "Title is required",
  required_details: "Ticket details is required",
  required_photo: "Photo details is required",
  KDW: "KWD",
  resendCodemessage: "Verification code is sent again",
  monthToPay: "You have a due payment",
  pleasureMessage: "Please pay for the due month first",
  errorMsgCardpayment: "you should check the card payment",
  settings: "Settings",
  /************************Messagerie******************/
  messages: "Messages",
  change_photo: 'Change Photo',
  upload: "Upload",
  empty: 'No payments, yet.',
  empty2: 'Pay with Tenantive, and your payment details will show here.',
  selectPlaceholderEmpty: 'Select property first',
  emailAddressPlaceholder: 'Enter your email address',
  failed: 'Failed',
  password_not_valid: 'You have entered invalid password',
  loadingHistory: 'Loading...',
  noMore_message: 'No more messages',
  update: 'Update',
  update_number: 'Update phone number',
  update_number_txt: 'Please enter the new phone number here',
  verify: 'Verify',
  newNumberErr: 'Phone number still the same',
  numberUsed: 'Phone number already in use!',
  all: 'All',
  type_here: 'Type here',
  UploadCivilID: 'Upload Civil ID',
  reference_id: 'Reference ID',
  noData: 'No Data !',
  unitStat: 'Unit',
  Please_input_your_name: "Name is required",
  name_not_valid: 'Your name must contain at least 5 character',
  id_req: 'ID number is required',
  browse: "Browse",
  or: "or",
  drag_and_drop: "Drag & Drop document",
  By_SignUp: "By signing up, you agree to Tenantive",
  terms_and_conditions: " Terms of Use and Privacy Policy",
  conditions: " Privacy Policy ",
  terms: " Terms of Use ",
  and: ' and ',
  ARFloorNumber: 'Floor number',
  ARUnitNumber: 'Unit number',
  ARPropertyAdr: 'Property address',
  forbiddenPay: "Sorry, your landlord has not enabled online rent collection yet",
  termsConds: 'Terms & Conditions',
  email_exist: "Email already exist",
  knet: 'KONNECT',
  cash: 'Cash',
  bankTransfer: 'Bank transfer',
  creditCard: 'Credit card',
  cheque: 'Cheque',
  rentHistory: 'Rent',
  depositReceipt: 'Security deposit receipt',
  contract_pending_notif1: 'You have ',
  contract_pending_notif2: ' hours to pay and  activate your contract for unit ',
  contract_success_notif: 'Congratulation! Your contract is now activated.',
  blockedTenant: 'You have been blocked from paying. Contact your landlord for more information',
  forbiddenPayDeactivatedLandlord: "Landlord's account is deactivated. Please reactivate it to access your account.",
  landlordDeactivated: "Landlord's account is deactivated. Contact your landlord for more information",
  sqft: "Sq.ft",
  sqm: "Sq.m",
  update_current_phone: 'Please enter the current phone number here',
  confirm_verification_code: "Confirm",
  phone_number_error: "please verify your phone number",
  frequencyItem: [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Annually', value: 'Annually' },
    { label: 'Quarterly (3 months)', value: 'Quarterly' },
    { label: 'Semiannually (6 months)', value: 'Semiannually' }
  ],
  frequencyPay: 'Payment frequency',
  lease: "Rental lease agreement",
  about_to_end1: "You have ",
  about_to_end2: " about to end",
  contracts: " contracts ",
  /* ------------claims--------------*/
  claims: 'Claims',
  submit_claim: 'Submit a Claim',
  claim_type: 'Claim type',
  slect_claim: 'Select claim type ',
  general_claim: 'General claim',
  payment_claim: 'Payment claim',
  new_claim: 'Start New Claim',
  customer_information: 'Customer information',
  mobile_number: 'Mobile number',
  issue_information: 'Issue information',
  complaint: 'Complaint',
  support_doc: 'Attach support documents',
  browsee: 'Browse',
  dnd: 'Drag & Drop files',
  forr: '* For ',
  forrr: '* For ',
  claimsGeneral: 'General complaints ',
  generalDuration: 'Tenantive shall respond within 15 working days. We may contact you for additional information regarding your claim.',
  claimsPay: 'Payment  complaints ',
  payDuration: 'through K-net Tenantive shall respond within 2 to 7 working days.  In case your claim is incomplete, Tenantive will contact you to complete your information not later than 2 working days.',
  select_prop: 'Select a property',
  select_unit: 'Select a unit',
  receipt_number: 'Receipt number',
  transaction_date: 'Transaction date',
  claims_rules: 'I hereby acknowledge that all the information mentioned above are true and correct, and I bear full responsibility for the incorrectness of such information.',
  send_claim: 'Send Claim',
  search_receipt: 'Search a receipt number',
  amount_refund: 'Amount requested for refund',
  add_amount: 'Add an amount',
  you_done: 'You’re done!',
  payment_done: 'Your complaint has been forwarded to the appropriate department and your request will be granted within 7 days.',
  general_done: 'Your complaint has been forwarded to the appropriate department who will contact you within 15 days.',
  behalf_landlord: 'On behalf the Landlord',
  required_mail: 'Your email is required',
  claims_history: 'Claims history',
  dateTime: 'Date & Time',
  viewClaim: 'View Claim',
  statusItem: [
    { label: 'In progress', value: 'in progress' },
    { label: 'Sent', value: 'unresolved' },
    { label: 'Resolved', value: 'resolved' }
  ],
  paymentClaimDays: 'Up to 7 working days',
  generalClaimDays: 'Up to 15 working days',
  name: "Name",
  Email: "Email",
  dateSearch: "Date",
  unit: "Unit",
  write_msg: 'Write a message',
  valid_email: "Please enter a valid Email",
  write_msg: 'Write a message ',
  general: 'General',
  payment: 'Payment',
  Action: "Action",
  Description: "Description",
  multiFactorAuth: "Security ",
  multiFactorAuth1: "Log in using two-factor authentication",
  BruteForceError: "You have been blocked for 5 minutes. Please retry again later.",
  off: 'Off',
  on: 'On',
  year: 'years',
  payment_of: "Payment of",
  monthly_rent: "Monthly rent",
  refNumber: "Reference number",
  MailVerifSuccessfully: 'Thank you!',
  MailVerifFailed: 'Email verification failed!',
  MailVerifDashboard: 'Go to Homepage',
  goToDashoardSuccess: 'Your email was successfully verified.',
  goToDashoardFailed: 'Either the link has already expired or you did not copy the URL properly.',
  no_msg: 'Communicate and share files with your Landlord or Tenantive Support via our Messaging tool',
  discount: 'Discount',
  arabic_numbers_error_message: "Please use english numbers only",
  civil_id_equal_to: "Civil ID/ ID number must be 12 digit",
  contract_renewed: "* Contract duration was renewed by the landlord from ",
  contract_renewed_with_rental: "with a monthly rental amount of",
  endDate: "to",
  KWD: "KWD",
  dayToAccept: 'You have 24 hours to accept the contract for the unit ',
  pasword_in_latin_caracters: "Password must only contain latin caracters and numbers, and one of this special caracters: d!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?",
  forbiddenPayDeposit: "You can’t pay in advance, pay the Deposit or the previous months",
  youEnteredAnInvalidPhoneNumber: "You entered an invalid phone number. Please verify or",
  signUp: 'Sign up',
  menuArabic: "العربية"
};
export default lang;
