import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { apiUrl } from "../config/config";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin } from "antd";

function AcceptPayAfterPay(props) {
    const location = useLocation();
    const { token } = useSelector(state => state.auth)
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transaction_id');
    const payMethod = queryParams.get('payMethod');
    const [isLoading, setIsLoading] = useState(true)
    const updatePayDeposit = () => {
        const formData = new FormData();
        formData.append("order_id", transactionId);
        formData.append("status", "succeeded");
        fetch(apiUrl + "api/tenant/acceptDepositPaymentRequest", {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + token },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setTimeout(() => {
                     setIsLoading(false)
            history.push('/tenantPay');
        }, 10000);
            })
    }
    const updatePayInvoice = () => {
        const formData = new FormData();
        formData.append("order_id", transactionId);
        formData.append("status", "succeeded");
        fetch(apiUrl + "api/tenant/invoiceAcceptPaymentRequest", {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + token },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setTimeout(() => {
                     setIsLoading(false)
            history.push('/tenantPay');
        }, 10000);
            })
    }
    const updatePayRent = () => {
        const formData = new FormData();
        formData.append("order_id", transactionId);
        formData.append("status", "succeeded");
        fetch(apiUrl + "api/tenant/acceptPaymentRequest", {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + token },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setTimeout(() => {
                     setIsLoading(false)
            history.push('/tenantPay');
        }, 10000);
            })
    }
    useEffect(() => {
        setIsLoading(true)
        switch (payMethod) {
            case "acceptDepositPaymentRequest":
                updatePayDeposit()
                break;
            case "acceptPaymentRequest":
                updatePayRent()
                break;
            case "invoiceAcceptPaymentRequest":
                updatePayInvoice()
                break;
            default:
                break;
        }
    }, [])
    const handleRequest = () => {
        if (isLoading) {
            return (<div
                style={{ position: "absolute", left: "50%", top: "20%" }}
                className="spinTenantContainer"
            >
                <Spin size="large" />
            </div>)
        }
    }
    return (
        <div>
            {
                handleRequest()
            }
        </div>
    );
}

export default AcceptPayAfterPay;