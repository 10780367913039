import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import withClearCache from "./ClearCache";
import MainRoot from "./routes/MainRoot";
import "antd/dist/antd.css";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor } from "./store/store";
import "../src/assets/style.css";
import "../src/assets/ar.css";
import "../src/assets/en.css";
const ClearCacheComponent = withClearCache(MainRoot)
const rooter = (
  <Provider store={ store } >
    <PersistGate persistor={ persistor }>
      <ClearCacheComponent />
    </PersistGate>
  </Provider>
);

ReactDOM.render(rooter, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
